.card {
  width: '100%';
  margin-bottom: 0.5em;
}

.cardBody {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.cardBodyImage {
  width: 50px;
  cursor: pointer;
}
