.button {
  width: 100%;
}

.badge {
  flex: 1;
  margin-bottom: 1em;
}

.shakeBell {
  animation: bellshake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  transform-origin: top;
}

@keyframes bellshake {
  0% {
    transform: rotate(0);
  }
  2% {
    transform: rotate(15deg);
  }
  4% {
    transform: rotate(-15deg);
  }
  6% {
    transform: rotate(10deg);
  }
  8% {
    transform: rotate(-10deg);
  }
  10% {
    transform: rotate(5deg);
  }
  12% {
    transform: rotate(-5deg);
  }
  14% {
    transform: rotate(3deg);
  }
  16% {
    transform: rotate(0);
  }
}
