.error {
  border-color: red;
  background-color: #ff000010;
}

.error :global(.ant-select-selector) {
  border-color: red !important;
  background: #ffefef !important;
}

.comingFromGoingToContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
