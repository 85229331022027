.container {
  display: flex;
  flex-flow: column;
  gap: 1em;
}

.week {
  display: flex;
  flex-flow: row wrap;
  gap: 1em;
}

.day {
  display: flex;
  flex-flow: column;
  gap: 1em;
}
