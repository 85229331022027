.Root {
}

.Root-header {
  background-color: var(--main-accent-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--main-bg-color);
}

.Root-main {
  color: var(--main-fg-color);
  margin: 1em;
}

.Root-footer {
}
