:root {
  --main-bg-color: #eae9e7;
  --main-fg-color: #123c6b;
  --main-accent-color: #61bb96;
  --menu-background-color: #8cd0b4;
}

body {
  margin: 0;
  background-color: var(--main-bg-color);
  color: var(--main-fg-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-menu-light {
  background: var(--menu-background-color);
}

.ant-menu-item-selected,
.ant-submenu-item-selected {
  color: inherit !important;
  background-color: var(--main-accent-color) !important;
}

.ant-menu-item,
.ant-submenu-item {
  border-radius: 0px !important;
  padding-inline: 12px !important;
}

.ant-collapse-header {
  align-items: center !important;
}

.ant-popconfirm-buttons {
  display: flex;
  flex-direction: row;
}
