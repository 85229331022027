.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  max-width: 24em;
  margin-bottom: 2em;
  margin-left: auto;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 1em;
}

.row + .row {
  border-top: 1px solid #d7dfe9;
}

.row span + span {
  margin-left: auto;
}
