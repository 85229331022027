.weekday {
    min-width: calc(1024px / 5);
    max-width: 20%;
}

.divider {
    height: inherit;
    padding-right: 1em;
}

@media (max-width: 670px) {
    .weekday {
        min-width: 100%;
    }
    .divider {
        display: none;
    }
}